import React from "react"
import RichTextRenderer from "../../../elements/RichTextRenderer"
import { GatsbyImage } from "gatsby-plugin-image"

interface ILocationDetail {
  locationHeading: string
  header: string
  content: any
  address: string
  image: any
  flag: {
    title: string
    fixed: any
    file: {
      url: string
    }
  }
}

const LocationDetailComp: React.FC<ILocationDetail> = ({ locationHeading, header, content, address, image, flag }) => {
  return (
    <div className="location-detail">
      <div className="full-width-image ">
        {image && (
          <GatsbyImage className="img-fluid" image={image.fluid} loading="lazy" alt={image.title || ""} />
        )}
      </div>
      <div className="container-fuild">
        <div className="row no-gutters flex flex-row-reverse bg-white justify-content-center text-center">
          <div className="col-lg-7 text-left">
            <div className="yellow-content fade-in-up">
              <h2>{header}</h2>
              {content && <RichTextRenderer json={content} />}
            </div>
          </div>
          <div className="col-lg-4 bg-white">
            <div className="location-flag fade-in-up">
              <h3 className="location-icon">{locationHeading}</h3>
              <p>{address}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LocationDetailComp
