import React, { useState } from "react"
import PlaySVG from "../SVGs/play.inline.svg"
import RichTextRenderer from "../../elements/RichTextRenderer"
import ModalVideo from "../../elements/ModalVideo"
import { IContentfulLink } from "../../elements/ContentfulLink/contentful-link"
import CTA from "../../elements/CTA"

interface IHeroMazeLocation {
  heading: any
  subheading?: string
  content: any
  image: {
    fluid: any
    title?: string
  }
  video: string
  button?: IContentfulLink
}

const HeroMazeLocation: React.FC<IHeroMazeLocation> = ({ heading, image, content, video, button }) => {
  const [modalShow, setModalShow] = useState(false)

  const openModal = (e: any) => {
    e.preventDefault()
    setModalShow(true)
  }

  const hideModal = () => {
    setModalShow(false)
  }

  return (
    <section className="hero hero-location">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            {video ? (
              <>
                <a className="js-video" href="#" onClick={openModal}>
                  <img srcSet={image?.fluid?.images?.fallback?.srcSet} alt={image.title || ""} className="img-fluid" />
                  <span className="play-icon play-icon-location">
                    <PlaySVG className="icon-sprite" />
                    <div className="sr-only">Play video</div>
                  </span>
                </a>
                <ModalVideo show={modalShow} onHide={hideModal} video={video} />
              </>
            ) : (
              <img srcSet={image?.fluid?.images?.fallback?.srcSet} alt={image.title || ""} className="img-fluid" />
            )}
          </div>
          <div className="col-md-12 col-lg-8 hero-video-content fade-in">
            <p className="h4">{heading}</p>
            <RichTextRenderer json={content} className="hero-location-rich-text" />
          </div>
        </div>
        <div className="col-xs-6 offset-lg-1">{button && <CTA {...button} />}</div>
      </div>
    </section>
  )
}

export default HeroMazeLocation
