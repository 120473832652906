import React from "react"
import { PageProps, graphql } from "gatsby"
import PathContext from "../contexts/path.context"
import Layout from "../layouts/Primary/layout"
import HeroMazeLocation from "../components/hero-maze-location"
import LocationDetailComp from "../components/location/detail"
import "../layouts/Primary/styles/skin/templates/_location.scss"
import Widget from "../components/widgets"
import OfficeFeatures from "../components/location/office"
import LatestJobs from "../components/LatestJobs"
import LinkedinNoscript from "./noscript/noscript-linkedin"
import FacebookNoscript from "./noscript/noscript-facebook"
import GTMNoscript from "./noscript/noscript-gtm"
import { IContentfulLink } from "../elements/ContentfulLink/contentful-link"

export interface ILocationDetail {
  title: string
  slug: string
  seoTitle: string
  seoDescription: string
  heroButton?: IContentfulLink
  heroVideo: string
  heroHeading: string
  heroContent: any
  heroImage: {
    fluid: any
  }

  locationHeading: string
  yellowContentHeader: string
  yellowContent: any
  yellowSectionImage: {
    fluid: any
  }
  locationAddress: {
    locationAddress: string
  }
  locationCountryImage: {
    fixed: any
  }

  latestRolesHeading: string
  latestRolesDescription: any
  noLatestRolesDescription: string
  noLatestRolesButton: any

  favoriteSpotsHeading: string
  favoriteSpots: Array<any>

  widgets: Array<any>
}

export interface ILocationDetailProps {
  contentfulCareersLocation: ILocationDetail
  allLever: {
    nodes: Array<any>
  }
}

interface ILocationDetailContext {
  id: string
  slug: string
  locale: string
  locations?: []
}

const LocationDetail: React.FC<PageProps<ILocationDetailProps, ILocationDetailContext>> = ({
  pageContext,
  path,
  data,
}) => {
  const locale = pageContext.locale || "en-US"
  const seoData = {
    title: data.contentfulCareersLocation.seoTitle || data.contentfulCareersLocation.title,
    description: data.contentfulCareersLocation.seoDescription,
  }
  return (
    <PathContext.Provider
      value={{
        current: path,
        slug: pageContext.slug,
        locale: locale,
        activeLanguage: locale.substr(0, 2),
      }}
    >
      <Layout seoData={seoData}>
        <HeroMazeLocation
          heading={data.contentfulCareersLocation.heroHeading}
          content={data.contentfulCareersLocation.heroContent}
          image={data.contentfulCareersLocation.heroImage}
          video={data.contentfulCareersLocation.heroVideo}
          button={data.contentfulCareersLocation.heroButton}
        />

        <LocationDetailComp
          locationHeading={data.contentfulCareersLocation.locationHeading}
          header={data.contentfulCareersLocation.yellowContentHeader}
          content={data.contentfulCareersLocation.yellowContent}
          address={
            data.contentfulCareersLocation.locationAddress &&
            data.contentfulCareersLocation.locationAddress.locationAddress
          }
          image={data.contentfulCareersLocation.yellowSectionImage}
          flag={data.contentfulCareersLocation.locationCountryImage}
        />


        <LatestJobs
          heading={data.contentfulCareersLocation.latestRolesHeading}
          content={data.contentfulCareersLocation.latestRolesDescription}
          jobs={data.allLever.nodes}
          contentNoJobs={data.contentfulCareersLocation.noLatestRolesDescription}
          buttonNoJobs={data.contentfulCareersLocation.noLatestRolesButton}
          isLocationPage={true}
          mainHeading={data.contentfulCareersLocation.title}
          locations={pageContext?.locations}
          withPagination
        />

        {data.contentfulCareersLocation.widgets &&
          data.contentfulCareersLocation.widgets.map((widgetData, index) => (
            <Widget {...widgetData} key={`widget-${index}`} />
          ))}
      </Layout>

      {/* Linkedin, Facebook and GTM noscript */}
      <LinkedinNoscript />
      <FacebookNoscript />
      <GTMNoscript />
    </PathContext.Provider>
  )
}

export default LocationDetail

export const query = graphql`
  query($id: String!, $locations: [String]) {
    allLever(filter: { categories: { location: { in: $locations } } }, sort: { fields: createdAt, order: DESC }) {
      nodes {
        ...JobPosting
      }
    }
    contentfulCareersLocation(id: { eq: $id }) {
      id
      title
      slug
      seoTitle
      seoDescription
      heroButton {
        ...ContentfulLink
      }
      heroHeading
      heroContent {
        ...RichTextField
      }
      heroImage {
        fluid: gatsbyImageData(layout: FULL_WIDTH, quality: 100, formats: [AUTO, WEBP])        
        title
      }
      heroVideo
      yellowSectionImage {
        title
        description
        fluid: gatsbyImageData( width: 1920 quality: 100 formats: [AUTO, WEBP])
      }
      yellowContentHeader
      yellowContent {
        ...RichTextField
      }

      locationHeading
      locationAddress {
        locationAddress
      }
      locationCountryImage {
        title
        fixed: gatsbyImageData(layout: FIXED width: 44 height: 30 quality: 100 formats: [AUTO, WEBP])        
        file {
          url
          contentType
        }
      }

      latestRolesHeading
      latestRolesDescription {
        ...RichTextField
      }
      noLatestRolesDescription
      noLatestRolesButton {
        ...ContentfulLink
      }

      favoriteSpotsHeading
      favoriteSpots {
        ...ComponentCard
      }

      widgets {
        ...ComponentCTA
      }
    }
  }
`
